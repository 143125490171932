import * as VueRouter from 'vue-router'
import SiteEditor from './views/SiteEditor'
import SitesList from './views/SitesList'
import Login from './views/Login'
import Register from './views/Register'
import Permission from './views/Permission'
import ResetPassword from './views/ResetPassword'
import UserManagementView from './views/UserManagementView'
import Settings from './views/Settings'


const routes = [
  { name: 'Login', path: '/login', component: Login },
  { name: 'Register', path: '/register', component: Register },
  { name: 'ResetPassword', path: '/reset-password', component: ResetPassword },
  { name: 'SitesList', path: '/site-list', component: SitesList },
  { name: 'UserManagementView', path: '/users', component: UserManagementView },
  { name: 'SiteEditor', path: '/site-edit/:uri', component: SiteEditor },
  { name: 'ComponentsEditor', path: '/global-components', component: SiteEditor, props: { isComponentView: true } },
  { name: 'Permission', path: '/site-permission', component: Permission },
  { name: 'Settings', path: '/settings', component: Settings },
  { path: '/', redirect: '/site-list' }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
})

export default router
