<template>
  <vue-final-modal
    v-model="showModal"
    :click-to-close="false"
    classes="flex justify-center items-center"
  >
    <div class="w-96 h-80 bg-white px-7 py-5 flex flex-col">
      <div class="flex text-lg mb-2">
        <span class="mr-auto">Add Component</span>
        <font-awesome-icon icon="times" class="opacity-50 cursor-pointer" @click="showModal=false" />
      </div>
      <div class="relative">
        <input
          type="text" class="w-full"
          v-model="name"
          :class="{
            'border-b-2': true,
            'bg-red-50': invalid 
          }"
          placeholder="component name"
          @click="loadGlobalComponents"
          @keyup="loadGlobalComponents"
          @blur="handleBlur"
        />
        <div class="bg-gray-200 absolute w-full mt-1">
          <div v-if="name && !invalid" class="cursor-pointer hover:bg-white py-1 px-2" @click="createNew">
            Create new '{{ name }}'
          </div>
        </div>
      </div>
      <div class="flex flex-wrap overflow-auto">
        <div v-if="nodeId && localComponents.length > 0" class="flex flex-wrap pt-2 w-full">
          <label class="w-full text-xs mb-1">Local components</label>
          <div v-for="comp of localComponents" :key="comp._id" class="w-1/2 px-2">
            {{ comp.name }}
            <button class="opacity-80 ml-2" @click="addDep(comp._id)">
              Add
            </button>
          </div>
        </div>
        <label class="w-full text-xs mb-1 pt-2">Global components</label>
        <div v-for="comp of globalComponents" :key="comp._id" class="w-1/2 px-2">
          <label>{{ comp.name }}</label>
          <div class="flex text-sm action-btns opacity-80">
            <button class="mr-2" @click="createFromGlobal(comp, true)">
              Copy
            </button>
            <button @click="createFromGlobal(comp, false)">
              Direct Use
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import * as compService from '../service/component'

export default {
  name: 'AddComponent',
  props: {
    components: {
      type: Array,
      default: () => ([])
    },
    nodeId: {
      type: String
    }
  },
  watch: {
    showModal () {
      this.loadGlobalComponents()
    }
  },
  computed: {
    localComponents () {
      const node = this.components.find(c => c._id === this.nodeId)
      return this.components.filter(c => (c.name !== 'app' && c._id !== this.nodeId && !node.deps.includes(c._id)))
    },
    cids () {
      return this.components.map(c => c._id)
    },
    invalid () {
      return !!(this.components.find(c => c.name === this.name))
    }
  },
  data () {
    return {
      name: '',
      globalComponents: [],
      showModal: false
    }
  },
  mounted () {
    this.loadGlobalComponents()
  },
  methods: {
    handleBlur () {
      if (!this.name) {
        this.needLoad = false
      }
    },
    addDep(compId) {
      this.$emit('addDep',compId)
      this.showModal = false
    },
    createNew () {
      this.needLoad = false
      const { name } = this
      this.$emit('new', {
        template: `<div>${name} component template</div>`,
        name,
        props: [],
        data: [],
        deps: []
      })
      this.name = ''
      this.globalComponents = []
      this.showModal = false
    },
    createFromGlobal (comp, copy = false) {
      this.needLoad = false
      this.$emit('new', {
        ...comp,
        is_global: !copy,
        _id: copy ? null : comp._id
      })
      this.name = ''
      this.globalComponents = []
    },
    async loadGlobalComponents () {
      this.needLoad = true
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(async () => {
        const res = await compService.getGlobalComponents(0, 5, this.name)
        if (this.needLoad) {
          this.globalComponents = res.components.filter(c => !this.cids.includes(c._id))
        }
      }, 800)
    }
  }
}
</script>
