<template>
  <div class="zoom-view" :class="{
    'modal-view': modal
  }">
    <div class="w-full h-full modal-content">
      <slot :modal="modal" />
      <span class="view-toggle" @click="modal = !modal">
        <font-awesome-icon v-if="!modal" icon="expand" />
        <font-awesome-icon v-else icon="times" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZoomView',
  data () {
    return {
      modal: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .zoom-view {
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    line-height: 1.5;
    position: relative;
    &.modal-view {
      .modal-content {
        position: fixed;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        background: white;
        border: 1px solid;
        padding: 10px 20px;
        z-index: 2000;
        overflow-y: auto;
      }
      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 1;
      }
    }
    .view-toggle {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
    }
  }
</style>
