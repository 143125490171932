<template>
  <div class="settings-page">
    <label @click="showModal = !showModal" class="cursor-pointer">
      <font-awesome-icon icon="cog" />
    </label>
    <vue-final-modal
      v-model="showModal"
      :click-to-close="false"
      :content-style="{
        width: '100vw',
        height: '100vh'
      }"
    >
      <div class="bg-white inline-flex px-6 py-4 w-full h-full overflow-auto">
        <div class="w-1/2">
          <h3 class="text-xl mb-3">Site Settings</h3>
          <div class="flex mb-2">
            <label class="font-medium mr-2">Site title:</label>
            <input
              type="text"
              :defaultValue="title"
              placeholder="Site title"
              class="flex-grow"
              @change="updateSiteTitle($event.target.value)"
            />
          </div>
          <div class="flex">
            <label class="font-medium mr-5">Auth</label>
            <div>
              <label class="mr-3">
                <input
                  :defaultChecked="loginInclude" type="checkbox" class="mr-1"
                  @change="updateField('loginInclude', $event.target.checked)"
                />
                Include Login
              </label>
              <label>
                <input
                  :disabled="!loginInclude"
                  :defaultChecked="loginRequired" type="checkbox" class="mr-1"
                  @change="updateField('loginRequired', $event.target.checked)"
                />
                Login Required
              </label>
              <label class="ml-3">
                <input
                  :defaultChecked="enableMobile" type="checkbox" class="mr-1"
                  @change="updateField('enableMobile', $event.target.checked)"
                />
                Enable Mobile
              </label>
            </div>
          </div>
          <div class="flex mb-2">
            <label class="font-medium mr-5">Auth api url</label>
            <select
              :value="authAPIMethod"
              @change="updateField('authAPIMethod', $event.target.value)"
            >
              <option>GET</option>
              <option>POST</option>
              <option>PUT</option>
            </select>
            <input
              class="w-96"
              type="text"
              placeholder="auth url"
              :defaultValue="authUrl"
              @change="updateField('authUrl', $event.target.value)"
            />
          </div>
          <div class="font-medium">External files</div>
          <div v-for="(file, idx) of orderedExtFile" :key="idx">
            <div v-if="idx === 0">Scripts</div>
            <div v-if="idx === this.filesCount['script']">Styles</div>
            <div class="flex w-full">
              <span class="mx-2">{{(idx >= this.filesCount['script'] ? idx - this.filesCount['script']: idx) + 1 }}:</span>
              <select
                :value="file.type"
                class="w-24 mr-2"
                @change="updateDepFile('type', $event.target.value, idx)"
              >
                <option value="style">style</option>
                <option value="script">script</option>
              </select>
              <input
                class="flex-1"
                type="text"
                :defaultValue="file.url"
                placeholder="url"
                @change="updateDepFile('url', $event.target.value, idx)"
                :disabled="file.url.startsWith('file:')"
              />
              <span
                class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                v-if="file.url.startsWith('file:')"
                @click="handleEditFile(file.url)"
              >
                <font-awesome-icon icon="edit" />
              </span>
              <span
                class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                @click="removeDepFile(idx)"
              >
                <font-awesome-icon icon="minus" />
              </span>

              <div class="flex ml-2">
                <span class="cursor-pointer ml-2" @click="sortFile(-1, idx)">
                  <font-awesome-icon icon="caret-up" />
                </span>
                <span class="cursor-pointer ml-2" @click="sortFile(1, idx)">
                  <font-awesome-icon icon="caret-down" />
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <select class="w-24 mr-2" v-model="newDep.type">
              <option value="style">style</option>
              <option value="script">script</option>
            </select>
            <input
              v-model="newDep.url"
              class="flex-1"
              type="text"
              placeholder="url"
              :disabled="newDep.url.startsWith('file:')"
            />
            <span
              v-if="newDep.url.startsWith('file:')"
              @click="newDep.url=''"
              class='mx-2'
            >
              <font-awesome-icon icon="times" />
            </span>
            <span class="mx-2">-or-</span>
            <input :key="newDep.url" type='file' @change="handleUploadFile" />
            <span
              class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
              @click="addDepFile"
            >
              <font-awesome-icon icon="plus" />
            </span>
          </div>
          <div class="flex mt-2">
            <label class="font-medium w-1/5">Full reload</label>
            <input class="text-right w-14" type="number" :defaultValue="fullReload" @change="updateField('fullReload', $event.target.value)" />secs
          </div>
          <div class="flex">
            <label class="font-medium w-1/5">Ajax reload</label>
            <input class="text-right w-14" type="number" :defaultValue="ajaxReload" @change="updateField('ajaxReload', $event.target.value)" />secs
          </div>
          <div class="flex flex-col mt-2">
            <label class="font-medium">Global Variables</label>
            <div
              v-for="(variable, idx) of globalVariables"
              :key="idx"
              class="flex"
            >
              <input
                :defaultValue="variable.name" type="text" placeholder="name" class="w-32 mr-2"
                @change="updateVariableField('name', $event.target.value, idx)"
              />
              <input
                :defaultValue="variable.value" type="text" placeholder="value" class="flex-1"
                @change="updateVariableField('value', $event.target.value, idx)"
              />
              <span
                class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                @click="removeVariable(idx)"
              >
                <font-awesome-icon icon="minus" />
              </span>
            </div>
            <div class="flex">
              <input v-model="newVariable.name" type="text" placeholder="name" class="w-32 mr-2" />
              <input v-model="newVariable.value" type="text" placeholder="value" class="flex-1" />
              <span
                class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                @click="addVariable()"
              >
                <font-awesome-icon icon="plus" />
              </span>
            </div>
          </div>
          <hr  class="my-10"/>
          <div>
            <div class="mb-12">
              <button class="px-3 py-1 rounded mr-3 cursor-pointer bg-gray-200" @click="save">Save</button>
              <button class="px-3 py-1 rounded mr-6 cursor-pointer bg-gray-200" @click="close">Close</button>
            </div>
            <label class="text-red-500 mb-3 inline-block">Danger Zone</label>
            <div class="flex">
              <button
                class="bg-red-500 text-white px-3 py-1 rounded mr-3 cursor-pointer"
                @click="$emit('updateURI')"
              >Change Site URI</button>
              <button
                class="bg-red-500 text-white px-3 py-1 rounded cursor-pointer"
                @click="$emit('deleteSite')"
              >Delete</button>
            </div>
          </div>
        </div>
        <div class="w-1/2 pl-2 h-full flex flex-col">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <label class="font-medium mb-2">Global Style Inject</label>
              <div class="max-h-96 overflow-auto">
                <TemplateEditor
                  :template="globalCss"
                  lang="css"
                  @set="updateCss"
                />
              </div>
            </div>
            <div class="flex flex-col mt-6 mb-6">
              <label class="font-medium mb-2">Global Javascript</label>
              <div class="max-h-96 overflow-auto">
                <TemplateEditor
                  :template="globalJs"
                  @set="updateJs"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showFileEdit"
        :click-to-close="false"
      >
        <div class="bg-white w-xl h-xl mx-auto my-24 max-w-3xl">
          <div class="px-3 py-2">
            Edit {{ editFile.key }}
          </div>
          <hr />
          <div class="max-h-96 overflow-auto">
            <TemplateEditor
              :template="editFile.content"
              @set="code => editFile.content = code"
            />
          </div>
          <hr />
          <div class="px-3 py-2 flex justify-end">
            <button
              class="px-3 py-1 rounded mr-3 cursor-pointer bg-gray-200"
              :class="{'opacity-50': editFileSaving}"
              @click="saveEditFile"
            >Save</button>
            <button class="px-3 py-1 rounded mr-6 cursor-pointer bg-gray-200" @click="closeEditFile">Close</button>
          </div>
        </div>
      </vue-final-modal>
    </vue-final-modal>
  </div>
</template>
<script>
import { api_host } from '../constants';
import { uploadFile } from '../service/site';
import TemplateEditor from './TemplateEditor';

export default {
  name: "SiteSettings",
  components: {
    TemplateEditor
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    extFiles: {
      type: Array,
      default: () => [],
    },
    fullReload: {
      type: Number,
      default: 0
    },
    ajaxReload: {
      type: Number,
      default: 0
    },
    loginInclude: {
      type: Boolean,
      default: false
    },
    loginRequired: {
      type: Boolean,
      default: false
    },
    authUrl: {
      type: String,
      default: () => ""
    },
    authAPIMethod: {
      type: String,
      default: () => "GET"
    },
    globalCss: {
      type: String,
      default: () => ""
    },
    globalJs: {
      type: String,
      default: () => ""
    },
    globalVariables: {
      type: Array,
      default: () => []
    },
    enableMobile: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: false,
      editFile: {
        key: '',
        content: ''
      },
      newDep: {
        type: "script",
        url: "",
        loading: false
      },
      newVariable: {
        name: '',
        value: ''
      },
      showFileEdit: false,
      editingFile: {
        key: '',
        content: ''
      },
      editFileSaving: false
    };
  },
  computed: {
    orderedExtFile () {
      return [...this.extFiles].sort((a, b) => {
        return a.type.localeCompare(b.type) || a.order - b.order
      })
    },
    filesCount () {
      return {
        'script': this.extFiles.filter(s => s.type === 'script').length,
        'style': this.extFiles.filter(s => s.type === 'script').length
      }
    },
  },
  methods: {
    close() {
      this.showModal = false;
    },
    save() {
      this.$emit('save')
      this.close()
    },
    updateDepFile(field, value, idx) {
      const newExtFiles = this.extFiles.map((ext, eidx) => {
        return eidx === idx
          ? {
              ...ext,
              [field]: value,
            }
          : ext;
      });
      this.$emit("update", { extFiles: newExtFiles });
    },
    removeDepFile(idx) {
      const clone = JSON.parse(JSON.stringify(this.extFiles));
      clone.splice(idx, 1);
      this.$emit("update", { extFiles: clone });
    },
    addDepFile() {
      this.$emit("update", {
        extFiles: this.extFiles.concat({
          id: this.extFiles.length,
          type: this.newDep.type,
          url: this.newDep.url,
          order: this.filesCount[this.newDep.type],
        }),
      });
      this.newDep = {
        type: "script",
        url: "",
      };
    },
    removeVariable (idx) {
      const clone = JSON.parse(JSON.stringify(this.globalVariables));
      clone.splice(idx, 1);
      this.$emit("update", { globalVariables: clone });
    },
    addVariable () {
      this.$emit("update", {
        globalVariables: this.globalVariables.concat({
          name: this.newVariable.name,
          value: this.newVariable.value,
        }),
      });
      this.newVariable = {
        name: "",
        value: "",
      };
    },
    updateVariableField (field, value, idx) {
      const clone = JSON.parse(JSON.stringify(this.globalVariables));
      clone[idx][field] = value
      this.$emit("update", { globalVariables: clone });
    },
    updateSiteTitle(title) {
      this.$emit("update", { siteTitle: title });
    },
    updateField(field, value) {
      console.log('field, value: ', field, value);
      this.$emit("update", { [field]: value });
    },
    updateJs(code) {
      this.updateField('globalJs', code)
    },
    updateCss(code) {
      this.updateField('globalCss', code)
    },
    async handleUploadFile(ev) {
      this.newDep.loading = true
      const res = await uploadFile(ev.target.files[0])
      console.log('res: ', res);
      this.newDep.url = `file:${res.key}`
      this.newDep.loading = false
    },
    async handleEditFile(url) {
      this.showFileEdit = true
      let type = ''
      const content = await fetch(url.replace('file:', `${api_host}/s3/`)).then(res => {
        type = res.headers.get('Content-Type')
        return res.text()
      })
      this.editFile = {
        key: url,
        type,
        content
      }
    },
    async saveEditFile() {
      this.editFileSaving = true
      console.log(this.editFile.content)
      const blob = new Blob([this.editFile.content], {type: this.editFile.type});
      const file = new File([blob], this.editFile.key, { type: this.editFile.type })
      const res = await uploadFile(file, this.editFile.key.replace('file:', ''))
      console.log(res)
      this.editFileSaving = false
      this.closeEditFile()
    },
    closeEditFile () {
      this.showFileEdit = false
      this.editFile = { key: '', content: '' }
    },
    sortFile (direction, idx) {
      if (this.orderedExtFile[idx + direction].type !== this.orderedExtFile[idx].type) {
        return
      }
      const updatedFiles = this.orderedExtFile.map((f, fIdx) => {
        return {
          ...f,
          order: fIdx === idx
            ? fIdx + direction
            : fIdx === idx + direction
              ? fIdx - direction
              : f.order
        }
      })
      this.$emit("update", {
        extFiles: updatedFiles,
      });
    }
  },
};
</script>
<style scoped>
.code-field {
  font-family: consolas;
}
</style>
