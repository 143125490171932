<template>
  <div class="w-screen h-screen flex items-center justify-center bg-gray-100">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div class="text-lg mb-5">
        Dynamic Builder
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">email</label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" v-model="email" />
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" v-model="password" />
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" v-model="passwordConirm" />
      </div>
      <div v-if="error" class="text-red-500 text-xs italic mt-3">
        {{ error }}
      </div>
      <button
        @click="register"
        class="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5"
        :class="{
          'opacity-70': !valid,
          'hover:bg-blue-700': valid
        }"
      >
        Register
      </button>
      <div class="mt-3 text-sm blue">
        Already have account? <router-link to="login">Login</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from '../service/auth'

export default {
  name: 'Register',
  data () {
    return {
      email: '',
      password: '',
      passwordConirm: '',
      error: ''
    }
  },
  computed: {
    valid () {
      return this.email && this.password && this.password === this.passwordConirm
    }
  },
  methods: {
    async register () {
      try {
        console.log('this.email, this.password: ', this.email, this.password);
        await authService.registerApi(this.email, this.password)
        window.location.href = '/login'
      } catch (err) {
        this.error = err
      }
    }
  }
}
</script>
