import { api_host } from '../constants'

export const createComponent = async (data) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/comps/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const updateComponent = async (id, data) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/comps/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const getGlobalComponents = async (page = 0, page_size = 10, searchKey = '') => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/comps/globals?skip=${page * page_size}&take=${page_size}&name=${searchKey}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const offGlobal = async (cids) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/comps/off-global`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ cids })
  }).then(res => res.json())
}
