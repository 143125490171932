<template>
  <prism-editor class="h-full overflow-auto editor" v-model="code" :highlight="highlighter" line-numbers></prism-editor>
</template>
<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-coy.css';

export default {
  name: 'TemplateEditor',
  components: { PrismEditor },
  props: {
    template: {
      type: String,
      default: () => ''
    },
    lang: {
      type: String,
      default: () => 'js'
    }
  },
  computed: {
    code: {
      get () {
        return this.template
      },
      set (v) {
        this.$emit('set', v)
      }
    }
  },
  methods: {
    highlighter(code) {
      switch (this.lang) {
        case 'css':
          return highlight(code, languages.text)
        case 'js':
        default:
          return highlight(code, languages.js)
      }
    },
  },
}
</script>
<style scoped>
>>> .prism-editor__container {
  min-height: 100%;
}
>>> textarea:focus-visible {
  outline: none;
  border: none;
}
.editor {
  background: #ffffff;
  color: #000;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}
</style>
