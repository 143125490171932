<template>
  <div class="">
    <PageHeader />
    <div class="px-3">
      <div class="text-xl">
        Permissions Table
      </div>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Site</th>
            <th>Permissions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="userSite of userSites" :key="userSite._id" class="bg-lightgrey">
            <td v-html="getName(userSite.user)"></td>
            <td v-html="userSite.site.uri"></td>
            <td v-html="userSite.type"></td>
            <td>
              <button @click="removePermission(userSite._id)" class="bg-darkgrey">Delete <span class="font-bold">&times;</span></button>
            </td>
          </tr>
          <tr class="bg-lightgrey">
            <td v-if="permissions.length === 0" class="text-lg" colspan="4">
              No permissions yet.
            </td>
          </tr>
          <tr>
            <td>
              <select v-model="userId" class="bg-darkgrey">
                <option v-for="user of users" :key="user._id" :value="user._id" v-html="getName(user)"></option>
              </select>
            </td>
            <td>
              <select v-model="siteId" class="bg-darkgrey">
                <option v-for="site of sites" :key="site._id" :value="site._id" v-html="site.uri"></option>
              </select>
            </td>
            <td>
              <input type="checkbox" id="edit-permission" v-model="permissionEdit" />
              <label for="edit-permission">Edit</label>
              <input type="checkbox" id="delete-permission" v-model="permissionDelete" />
              <label for="delete-permission">Delete</label>
            </td>
            <td>
              <button @click="addPermission(userId, siteId, permissionEdit, permissionDelete)" class="bg-darkgrey">+ Add New Permission</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="loading">
        Loading ...
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from './components/PageHeader'
import * as permissionService from '../service/permission'
import * as authService from '../service/auth'
import * as siteService from '../service/site'

export default {
  name: 'Permission',
  components: {
    PageHeader
  },
  data () {
    return {
      loading: false,
      permissionEdit: false,
      permissionDelete: false,
      userId: '',
      siteId: '',
      permissions: [],
      userSites: [],
      users: [],
      sites: []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        this.users = await authService.getUsers()
        this.sites = await siteService.getSites()
        this.loadPermissionsData()
      } catch (err) {
        console.log('err: ', err);
      }
      this.loading = false
    },
    findSameUserSite(permission) {
      let permissionIndex = -1
      this.userSites.forEach((userSite, index) => {
        if (userSite.user._id === permission.user && userSite.site._id === permission.site) {
          permissionIndex = index
          return index
        }
      })
      return permissionIndex
    },
    async loadPermissionsData() {
      this.permissions = await permissionService.getPermissions()
      this.userSites = []
      this.permissions.forEach(permission => {
        const permissionIndex = this.findSameUserSite(permission)
        if (permissionIndex >= 0) {
          this.userSites[permissionIndex].type += '/' + permission.type
        } else {
          this.users.forEach(user => {
            if (user._id === permission.user) {
              permission.user = user
            }
          })
          this.sites.forEach(site => {
            if (site._id === permission.site) {
              permission.site = site
            }
          })
          this.userSites.push(permission)
        }
      });
    },
    async removePermission (permissionId) {
      await permissionService.deletePermission(permissionId)
      this.loadPermissionsData()
    },
    async addPermission (userId, siteId, permissionEdit, permissionDelete) {
      const data = {
        permission: {
          user: userId,
          site: siteId,
          edit: permissionEdit,
          delete: permissionDelete
        },
      };
      await permissionService.createPermission(data);
      this.loadPermissionsData()
    },
    getName(user) {
      if (user.first_name) {
        if (user.last_name) {
          return `${user.first_name} ${user.last_name}`
        } else {
          return user.first_name
        }
      } else if (user.last_name) {
        return user.last_name
      } else {
        return user.email
      }
    }
  }
}
</script>

<style scoped>
  thead th {
    text-align: left;
    padding: .5rem;
  }
  tbody tr:not(:last-child) td {
    padding: .5rem 4.5rem .5rem .5rem;
  }

  tbody tr:last-child td {
    padding: .5rem 0 0;
  }

  tbody tr:last-child td>* {
    padding: .2rem .5rem;
  }

  .bg-lightgrey {
    background-color: rgb(215, 215, 215);
  }
  
  .bg-darkgrey {
    background-color: rgb(196, 196, 196);
  }
</style>
