<template>
  <div class="wrapper">
    <Sidebar :class="!isOpenSidebar ? 'close-sidebar' : ''" />
    <div
      id="wrapper-content"
      :class="!isOpenSidebar ? 'ml-0' : 'content-wrapper-margin'"
    >
      <PageHeader :isListPage="true" @toggleSidebar="toggleSidebar" />
      <div class="dashboard-wrapper">
        <div class="content-top-bar">
          <div class="row align-items-center">
            <div class="col-sm-3 col-md-6">
              <!-- <h5>Sites</h5> -->
              <input
                v-model="searchText"
                type="text"
                class="text-input search-site-text"
                placeholder="Search by email or site"
              />
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- content-top-bar -->

        <div class="dashboard-table" id="sites-table">
          <div
            v-if="loading || copying"
            class="bg-gray-500 bg-opacity-25 fixed top-0 left-0 w-screen h-screen flex items-center justify-center"
          >
            <div
              class="spinner-border"
              style="width: 3rem; height: 3rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div
            v-else-if="filteredUserSites.length === 0"
            class="h-screen flex items-center justify-center"
          >
            <div class="text-lg">
              No permissions yet.
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Site</th>
                  <th>Permissions</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- if no result -->
                <tr v-if="filteredUserSites.length === 0">
                  <td></td>

                  <td>No permissions yet.</td>
                  <td></td>
                  <td></td>
                </tr>
                <!-- listing -->
                <tr v-for="userSite of filteredUserSites" :key="userSite._id">
                  <td>
                    <div v-html="getName(userSite.user)"></div>
                  </td>
                  <td>
                    <div v-html="userSite.site.uri"></div>
                  </td>
                  <td>
                    <div v-html="userSite.type"></div>
                  </td>
                  <td>
                    <div @click="removePermission(userSite._id)" class="pointer"><i class="fa-solid fa-trash-can"></i> Delete</div>
                  </td>
                </tr>
                <tr>
                  <td class="select-user">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="userId"
                    >
                      <option  v-for="user of users" :key="user._id" :value="user._id" v-html="getName(user)"></option>
                    </select>
                  </td>
                  <td class="select-user">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="siteId"
                    >
                      <option v-for="site of sites" :key="site._id" :value="site._id" v-html="site.uri"></option>
                    </select>
                  </td>
                  <td>
                    <div class="dashboard-table-permission">
                      <label for="check-input11">
                        <input
                          id="check-input11"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          v-model="permissionEdit"
                        />
                        Edit
                      </label>
                      <label for="check-input12">
                        <input
                          id="check-input12"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          v-model="permissionDelete"
                        />
                        Delete
                      </label>
                    </div>
                    <!-- dashboard-table-icons -->
                  </td>
                  <td>
                    <div class="dashboard-table-permission">
                      <div @click="addPermission(userId, siteId, permissionEdit, permissionDelete)" class="btn btn-black">
                        <i class="fa-solid fa-plus"></i> Add New Permission</div
                      >
                    </div>
                    <!-- dashboard-table-icons -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- dashboard-table -->
      </div>
      <!-- dashboard-wrapper -->
    </div>
  </div>
</template>
<script>
import PageHeader from "./components/PageHeader";
import Sidebar from "@/components/Sidebar.vue";
import * as permissionService from "../service/permission";
import * as authService from "../service/auth";
import * as siteService from "../service/site";

export default {
  name: "UserManagementView",
  components: {
    PageHeader,
    Sidebar,
  },
  data() {
    return {
      loading: false,
      siteName: "",
      sites: [],
      copying: false,
      archivedOnly: false,
      searchText: "",
      isOpenSidebar: true,
      permissionEdit: false,
      permissionDelete: false,
      userId: "",
      siteId: "",
      permissions: [],
      userSites: [],
      users: [],
    };
  },
  computed: {
    filteredUserSites() {
      let search = this.searchText.trim().toLowerCase();
      if (search) {
        return this.userSites.filter(
          (userSite) =>
          this.getName(userSite.user)?.toLowerCase()?.includes(search) ||
          userSite?.site?.uri?.toLowerCase()?.includes(search)
        );
      }
      return this.userSites;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.users = await authService.getUsers();
        this.sites = await siteService.getSites();
        this.loadPermissionsData();
      } catch (err) {
        console.log("err: ", err);
      }
      this.loading = false;
    },
    findSameUserSite(permission) {
      let permissionIndex = -1;
      this.userSites.forEach((userSite, index) => {
        if (
          userSite.user._id === permission.user &&
          userSite.site._id === permission.site
        ) {
          permissionIndex = index;
          return index;
        }
      });
      return permissionIndex;
    },
    async loadPermissionsData() {
      this.permissions = await permissionService.getPermissions();
      this.userSites = [];
      this.permissions.forEach((permission) => {
        const permissionIndex = this.findSameUserSite(permission);
        if (permissionIndex >= 0) {
          this.userSites[permissionIndex].type += "/" + permission.type;
        } else {
          this.users.forEach((user) => {
            if (user._id === permission.user) {
              permission.user = user;
            }
          });
          this.sites.forEach((site) => {
            if (site._id === permission.site) {
              permission.site = site;
            }
          });
          this.userSites.push(permission);
        }
      });
    },
    async removePermission(permissionId) {
      await permissionService.deletePermission(permissionId);
      this.loadPermissionsData();
    },
    async addPermission(userId, siteId, permissionEdit, permissionDelete) {
      const data = {
        permission: {
          user: userId,
          site: siteId,
          edit: permissionEdit,
          delete: permissionDelete,
        },
      };
      await permissionService.createPermission(data);
      this.loadPermissionsData();
    },
    getName(user) {
      if (user.first_name) {
        if (user.last_name) {
          return `${user.first_name} ${user.last_name}`;
        } else {
          return user.first_name;
        }
      } else if (user.last_name) {
        return user.last_name;
      } else {
        return user.email;
      }
    },
    toggleSidebar(){
      this.isOpenSidebar = !this.isOpenSidebar
    }
  },
};
</script>
<style>
.close-sidebar {
  margin-left: -260px !important;
}
.search-site-text {
  width: 100%;
  max-width: 300px;
}
.archived-label {
  margin-left: 4px;
}
#sites-table {
  height: calc(100vh - 156px);
  overflow: auto;
}
</style>
