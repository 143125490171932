<template>
  <div class="w-screen h-screen flex items-center justify-center bg-gray-100">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div class="text-lg mb-5">
        Dynamic Builder - Reset password
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">New Password</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="password" v-model="password" />
      </div>
      <button @click="resetPassword"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5">
        Reset
      </button>
    </div>
  </div>
</template>
<script>
import * as authService from '../service/auth'

export default {
  name: 'reset-password',
  data() {
    return {
      user: null,
      token: '',
      password: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const token = new URLSearchParams(window.location.search).get('token')
      window.localStorage.setItem('token', token)
      try {
        this.user = await authService.verifyToken()
        this.token = token
      } catch (err) {
        window.localStorage.removeItem('token')
        window.location.href = '/login'
      }
    },
    async resetPassword() {
      await authService.updateUser(this.user._id, { password: this.password })
      this.$notify({
        type: "success",
        text: "Password updated",
      });
      this.$router.push({
        name: 'Login'
      })
    }
  }
}

</script>
