import { api_host } from '../constants'

export const getPermissions = async () => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/permissions/list`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const createPermission = async (data) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/permissions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const deletePermission = async (id) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/permissions/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}
