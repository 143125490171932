import { createApp } from 'vue'
import { vfmPlugin } from 'vue-final-modal'
import JsonViewer from "vue3-json-viewer"
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import router from './routes'
import './assets/style/style.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faMinus, faCaretRight, faExpand, faCheck, faEye, faEdit, faCopy,
  faCog, faRedo, faTimes, faArrowRight, faArchive, faBackward, faCaretUp, faCaretDown,
  faInfo, faFrownOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPlus, faMinus, faCaretRight, faExpand, faCheck, faCopy, faTrash, faArchive,
  faCog, faRedo, faTimes, faArrowRight, faEye, faEdit, faInfo, faFrownOpen, faCaretUp, faCaretDown,
  faBackward)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(vfmPlugin)
  .use(JsonViewer)
  .use(Notifications)
  .use(router)
  .mount('#app')
