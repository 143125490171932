<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      :click-to-close="false"
      classes="flex justify-center items-center"
    >
      <div class="bg-white px-10 py-8">
        <h3 class="mb-6">Site is not created for this uri - <b>{{ uri }}</b>. <br/>Create new site?</h3>
        <button @click="save" class="bg-gray-800 text-white px-3 py-1 rounded-md mr-2">
          Create
        </button>
        <button @click="createGlobalComponent" class="bg-gray-800 text-white px-3 py-1 rounded-md">
          Create Global component
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
export default {
  name: 'CreateSiteModel',
  props: {
    uri: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showModal: true
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    createGlobalComponent () {
      this.$emit('create-global')
    }
  }
}
</script>
