<template>
  <div class="wrapper">
    <Sidebar :class="!isOpenSidebar ? 'close-sidebar' : ''" />
    <div
      id="wrapper-content"
      :class="!isOpenSidebar ? 'ml-0' : 'content-wrapper-margin'"
    >
      <PageHeader :isListPage="true" @toggleSidebar="toggleSidebar" />
      <div class="dashboard-wrapper">
        <div>
          <div class="api-key-text">API key:</div>
          <div>
            <input
              v-model="user.api_key"
              type="text"
              class="text-input search-site-text"
              placeholder="API Key"
              disabled
            />
            <span class="copy-icon" @click="copyToClipboard">
              <!-- You can use an icon library like Font Awesome for the copy icon -->
              <i class="fa fa-copy"></i>
            </span>
            <a
              @click="generateApiKey"
              href="#"
              id="create-key-btn"
              class="btn btn-default btn-bla ck"
            >
              <div
                v-if="loading"
                class="spinner-border"
                style="width: 1.5rem; height: 1.5rem"
                role="status"
              >
              </div>
              <span v-else> <i class="fa fa-key"></i> Create API Key </span>
            </a>
          </div>
          <!-- <p class="ap-key-description">
            Generating a new API key will invalidate any existing key for this
            user.
          </p> -->
        </div>
      </div>
      <!-- dashboard-wrapper -->
    </div>
  </div>
</template>
<script>
import PageHeader from "./components/PageHeader";
import Sidebar from "@/components/Sidebar.vue";
import * as authService from "../service/auth";

export default {
  name: "SitesList",
  components: {
    PageHeader,
    Sidebar,
  },
  data() {
    return {
      loading: false,
      copying: false,
      isOpenSidebar: true,
      user: authService.getUser(),
    };
  },
  methods: {
    async generateApiKey() {
        if (this.loading) {
            return
        }
      try {
        if (window.confirm('Generating a new API key will invalidate the current key and stop it from working on all devices. Do you want to continue?')) {
            this.loading = true;
            this.user = await authService.generateNewApiKey();
        }
      } catch (err) {
        console.log("err===================>", err);
      }
      this.loading = false;
    },
    toggleSidebar() {
      this.isOpenSidebar = !this.isOpenSidebar;
    },
    copyToClipboard() {
      // Copy the api_key to the clipboard
      const input = document.createElement("input");
      input.setAttribute("value", this.user.api_key);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      alert("Copied to clipboard!");
    },
  },
};
</script>
<style>
.close-sidebar {
  margin-left: -260px !important;
}
.search-site-text {
  width: 100%;
  max-width: 400px;
}
.archived-label {
  margin-left: 4px;
}
#sites-table {
  height: calc(100vh - 156px);
  overflow: auto;
}
.api-key-text {
  font-weight: 700;
  padding-bottom: 4px;
}
.ap-key-description {
  font-size: smaller;
  margin-left: 10px;
}
#create-key-btn {
  margin-left: 10px;
}
.copy-icon {
  position: relative;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
  font-size: 16px;
}
</style>
