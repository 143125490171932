<template>
  <div class="w-screen h-screen flex items-center justify-center bg-gray-100">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div class="text-lg mb-5">
        Dynamic Builder
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">email</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email" v-model="email" />
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="password" v-model="password" />
      </div>
      <div v-if="error" class="text-red-500 text-xs italic mt-3">
        {{ error }}
      </div>
      <div class="text-xs mt-3 cursor-pointer" @click.stop="resetPassword = true">
        Request reset password
      </div>
      <button @click="login"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5">
        Login
      </button>
      <div class="mt-3 text-sm">
        New to builder? <router-link to="register">Register</router-link>
      </div>
    </div>
    <vue-final-modal
      classes="flex justify-center items-center"
      v-model="resetPassword"
    >
      <div class="bg-white px-6 py-4 w-full h-full overflow-auto flex flex-col">
        <div class="text-lg mb-5">Request Reset Password</div>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email" v-model="email" />
        <button @click="requestReset"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5">
          Send Reset Request
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import * as authService from '../service/auth'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: '',
      resetPassword: false
    }
  },
  methods: {
    async login() {
      try {
        console.log('this.email, this.password: ', this.email, this.password);
        await authService.loginApi(this.email, this.password)
        window.location.href = '/site-list'
      } catch (err) {
        this.error = err
      }
    },
    async requestReset() {
      await authService.requestResetPassword(this.email)
      this.$notify({
        type: "success",
        text: "Request sent",
      });
      this.resetPassword = false
    }
  }
}
</script>
