<template>
  <div class="flex flex-col">
    <label>
      url:
      <span class="w-full break-all underline">{{ remoteUrl }}</span>
    </label>
    <button @click="preview = !preview, loadResp()" class="mr-auto">
      {{ preview ? "Hide" : "Show" }} Preview
    </button>
    <ZoomView v-if="preview">
      <template v-slot="{ modal }">
        <div
          :class="{
            'w-full overflow-auto json-viewer': true,
            'h-72': !modal,
            'h-full': modal
          }"
        >
          <JsonViewer
            v-if="resp"
            :value="resp"
            @click="handleJVDraggable"
          />
        </div>
      </template>
    </ZoomView>
  </div>
</template>
<script>
import ZoomView from "../ui/ZoomView.vue";

export default {
  name: "DataSourceTest",
  components: {
    ZoomView,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    remoteUrl() {
      return this.item.resolve.url_params.reduce((url, param) => {
        const re = new RegExp(`:${param.name}`, "g");
        return url.replace(re, param.default_value);
      }, this.item.resolve.source_url);
    },
    remoteHeaders() {
      return (this.item.resolve.headers || []).reduce((acc, header) => {
        acc[header.name] = header.default_value;
        return acc;
      }, {});
    },
    remoteBody() {
      return (this.item.resolve.body_params || []).reduce((acc, item) => {
        acc[item.name] = item.default_value
        return acc
      }, {})
    }
  },
  data() {
    return {
      preview: false,
      resp: ''
    };
  },
  watch: {
    remoteUrl() {
      this.preview == false;
    },
  },
  methods: {
    async loadResp () {
      const requestParams = {method: this.item.method, headers: this.remoteHeaders}
      if (this.item.method === 'POST') {
        requestParams['body'] = JSON.stringify(this.remoteBody)
      }
      const res = await fetch(this.remoteUrl, requestParams).then(res => res.json())
      this.resp = res
      setTimeout(() => {
        this.handleJVDraggable()
      }, 1500)
    },
    handleDragStart (ev) {
      ev.dataTransfer.setData("text/plain", `{{ ${ev.target.innerText.replace(/:/g, '')} }}`);
    },
    handleJVDraggable () {
      document.querySelectorAll('.jv-key').forEach(elem => {
        elem.setAttribute('draggable', true)
        elem.removeEventListener('dragstart', this.handleDragStart)
        elem.addEventListener('dragstart', this.handleDragStart)
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.json-viewer {
  ::v-deep {
    .jv-code {
      padding: 0;
    }
    .jv-key {
      cursor: pointer;
      &:hover {
        background: rgba(0,0,0,0.2)
      }
    }
  }
}
</style>
