<template>
    <div v-if="isListPage" class="main-title-bar">
      <div class="main-title-bar-left">
        <button @click="toggleSidebar" id="toggle-navicon">
          <i class="fa-solid fa-bars"></i>
        </button>
        <h5 v-if="site" class="mr-10">{{ site.siteTitle || site.uri }}</h5>
        <h5 v-else class="mr-10">Dynamic Builder</h5>
      </div><!-- main-title-bar -->
      <div class="main-title-bar-right">
					<!-- <a v-if="isSuperAdmin()" href="/site-permission" class="btn btn-default btn-black"><i class="fa-solid fa-user-shield"></i> Permissions</a> -->
					<a @click="logout" href="#" class="btn btn-default btn-bla ck"><i class="fa-solid fa-power-off"></i> Logout</a>
			</div>
    </div>
    <div v-else class="w-full p-4 flex items-center bg-gray-100">
      <a href="/site-list" class="mr-3">
        <label class="text-3xl cursor-pointer">
          <img src="/favicon.ico" />
        </label>
      </a>
      <h3 v-if="site" class="mr-10">{{ site.siteTitle || site.uri }}</h3>
      <h3 v-else class="mr-10">Dynamic Builder</h3>
      <SiteSettings
        v-if="site && showSettings"
        :title="site.siteTitle"
        :uri="site.uri"
        :extFiles="site.extFiles"
        :fullReload="site.fullReload"
        :ajaxReload="site.ajaxReload"
        :loginInclude="site.loginInclude"
        :loginRequired="site.loginRequired"
        :authUrl="site.authUrl"
        :authAPIMethod="site.authAPIMethod"
        :globalCss="site.globalCss"
        :globalJs="site.globalJs"
        :globalVariables="site.globalVariables || []"
        v-bind="$attrs"
      />
      <div class="flex flex-grow justify-end">

        <div v-if="site" class="flex ml-10">
          <input
            :defaultValue="site.defaultQuery"
            type="text"
            class="bg-transparent border-b w-32 mr-2"
            placeholder="query string"
            :id="`querystring`"
            @change="$emit('update', { defaultQuery: $event.target.value })"
          />
          <button class="mr-6 ml-6" @click="openSitePage">
            View site
          </button>
        </div>
        <button v-if="site" class="mr-6 ml-6" @click="$emit('save')">
          Save
        </button>
        <button>
          <a v-if="isSuperAdmin()" href="/site-permission" class="d-inline-block">
            Permission
          </a>
        </button>

        <div v-if="site" class="flex items-center ml-auto">
          <div class="flex flex-col mr-4">
            <small>Version</small>
            <select :value="selectedVersion" @change="$emit('selectVersion', $event.target.value)">
              <option v-for="version of versionData.versions" :key="version" :value="version">
                {{version}}
                <small class="mx-2" v-if="version === versionData.current_version">
                  current
                </small>
                <small class="mx-2" v-if="version === versionData.published_version">
                  published
                </small>
              </option>
            </select>
          </div>
          <button
            v-if="selectedVersion !== versionData.published_version"
            class="cursor-pointer ml-3"
            title="Set as published version"
            @click="publishVersion"
          >
            <font-awesome-icon icon="check" />
          </button>
          <button
            v-if="selectedVersion !== versionData.current_version"
            class="cursor-pointer ml-3"
            title="Set as current version"
            @click="$emit('setMetaVersion', {
              current_version: selectedVersion
            })"
          >
            <font-awesome-icon icon="edit" />
          </button>
          <button
            class="cursor-pointer ml-3"
            title="Add New Version"
            @click="$emit('addNewVersion')"
          >
            <font-awesome-icon icon="copy" />
          </button>
          <button
            v-if="![versionData.published_version, versionData.current_version].includes(selectedVersion)"
            class="cursor-pointer ml-3 text-red-400"
            title="Delete this Version"
            @click="deleteVersion"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>

        <button class="ml-10" @click="logout">
          Logout
        </button>
      </div>
  </div>
</template>
<script>
import * as authService from '../../service/auth'
import SiteSettings from '../../components/SiteSettings.vue'
import { viewer_url } from '../../constants'

export default {
  name: 'PageHeader',
  components: { SiteSettings },
  props: {
    site: {
      type: Object,
      default: () => null
    },
    showSettings: {
      type: Boolean,
      default: true
    },
    versionData: {
      type: Object,
      default: () => ({
        versions: []
      })
    },
    selectedVersion: String,
    isListPage: {
      type: Boolean,
      default: false
    },
  },
  mounted () {
    if (this.site && this.site.uri) {
      this.$emit('update', { name: this.site.uri })
    }
  },
  methods: {
    openSitePage () {
      const { uri } = this.$route.params;
      const query = document.querySelector(`#querystring`).value
      const url = `${viewer_url}/${uri}?${query}${query ? '&': ''}v=${this.selectedVersion}`
      window.open(url, '_blank')
    },
    logout () {
      window.localStorage.removeItem('token')
      window.location.href = '/login'
    },
    isSuperAdmin() {
      const user = authService.getUser()
      console.log('user: ', user);
      return user && user.is_super_admin
    },
    async publishVersion () {
      if (window.confirm('Are you sure want to publish this version?')) {
        this.$emit('setMetaVersion', {
          published_version: this.selectedVersion
        })
      }
    },
    async deleteVersion () {
      if (window.confirm('Are you sure want to delete this version?')) {
        this.$emit('deleteVersion', this.selectedVersion)
      }
    },
    toggleSidebar(){
      this.$emit("toggleSidebar")
    }
  }
}
</script>
