<template>
  <div class="relative" :class="{
    'bg-white': expand,
  }">
    <div
      class="flex justify-between cursor-pointer hover:bg-white items-center"
      :class="{
        [cClass]: true,
        'bg-white border-b': expand
      }"
      @click="expand = !expand"
    >
      <span>{{ title }}</span>
      <font-awesome-icon icon="caret-right"
        :class="{
          'transform rotate-90': expand
        }"
        class="mr-2"
      />
    </div>
    <div :class="{
      'max-h-screen bg-white border-b-2': expand,
      'max-h-0': !expand,
      [cClass]: true
    }" class="overflow-y-auto">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      default: () => 'Accordion'
    },
    cClass: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      expand: false
    }
  }
}
</script>
