<template>
  <div class="w-full h-screen max-h-screen flex flex-col main-container">
    <!-- <PageHeader
      :site="site"
      :versionData="versionData"
      :showSettings="!isComponentView"
      :selectedVersion="selectedVersion"
      @update="updateSiteSettings"
      @save="handleSave"
      @updateURI="updateURI"
      @deleteSite="deleteSite"
      @selectVersion="selectVersion"
      @addNewVersion="addNewVersion"
      @setMetaVersion="setMetaVersion"
      @deleteVersion="deleteVersion"
    /> -->

    <div class="flex-1 flex h-full min-h-0">
      <div class="w-52 pl-2" id="toggle-sidebar" :class="!isOpenSidebar ? 'close-sidebar': ''" >
        <figure class="sidebar-logo"><a href="/"><img src="@/assets/main-logo.png" class="img-fluid"></a></figure>
        <div v-if="!isComponentView" class="h-1/2 overflow-auto ml-2">
          <ComponentTree
            :components="components"
            :currentComponent="currentComponent"
            @openAdd="openAdd"
            @select:component="selectComponent"
            @remove:dep="handleRemoveDep"
          />
        </div>
        <div class="h-1/2 overflow-auto ml-2">
          <ComponentList
            :components="components"
            :currentComponent="currentComponent"
            :isComponentView="isComponentView"
            :isEditable="isEditable"
            @select:component="selectComponent"
            @setDeps="setDeps"
            @remove="handleRemoveComponent"
            @openAdd="openAdd"
          />
        </div>
      </div>
      <div class="w-full main-editor-wrapper" id="wrapper-content" :class="!isOpenSidebar ? 'ml-0' : 'content-wrapper-margin'">
        <SiteEditPageHeader
        :site="site"
        :versionData="versionData"
        :showSettings="!isComponentView"
        :selectedVersion="selectedVersion"
        @update="updateSiteSettings"
        @save="handleSave"
        @updateURI="updateURI"
        @deleteSite="deleteSite"
        @selectVersion="selectVersion"
        @addNewVersion="addNewVersion"
        @setMetaVersion="setMetaVersion"
        @deleteVersion="deleteVersion"
        @toggleSidebar="toggleSidebar"
        />
        <div class="flex-1 flex h-full dashboard-wrapper drag-container main-container">
          <div class="flex flex-col" id="drag-left">
            <div class="h-100" :class="{ disabled: !isEditable }">
              <TemplateEditor
                v-if="currentComponent"
                :template="currentComponent.template"
                @set="updateTemplate"
              />
            </div>
            <!-- <div class="h-1/2">
              <preview ref="previewRef" :baseurl="previewurl" />
            </div> -->
          </div>
          <div v-show="isEditable" class="dragbar" id="dragbar">
            <img src="@/assets/resizer.svg" alt="" srcset="">
          </div>
          <div class="w-1/5 ml-2 overflow-auto settings-wrapper" id="drag-right">
            <!-- <label class="px-2 font-bold"> Component settings</label> -->
            <div
              v-if="!isComponentView && currentComponent && !isEditable"
              class="px-2 pt-2"
            >
              <small>
                This is global component. Editing this will affect all other sites
                using the same component. you can edit this anyway or make copy for
                your site only.
              </small>
              <div class="flex justify-end">
                <button
                  class="mr-5 text-red-700"
                  @click="forceEdit[currentComponent._id] = true"
                >
                  Edit
                </button>
                <button class="mr-4" @click="makeGCompCopy">Make a Copy</button>
              </div>
            </div>
            <div :class="{ disabled: !isEditable }">
              <ComponentConfig
                v-if="currentComponent"
                :currentComponent="currentComponent"
                :key="`${currentComponent._id}-config`"
                :isComponentView="isComponentView"
                :settings="{
                  is_global: currentComponent.is_global,
                  name: currentComponent.name,
                }"
                @settings:change="setSettings"
              />
              <PropsEdit
                v-if="currentComponent"
                :key="`${currentComponent._id}-prop`"
                :propsList="currentComponent.props"
                @set="setProps"
              />
              <DataSource
                v-if="currentComponent"
                :key="`${currentComponent._id}-data`"
                :data="currentComponent.data"
                @update="handleSourceUpdate"
                @remove="handleRemoveDataSource"
                @add="handleAddDataSource"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateSiteModel
      v-if="!loading && !isComponentView && !siteId"
      :uri="uri"
      @save="createNewSite"
      @create-global="createGlobalComponent"
    />
    <AddComponent
      ref="addCompRef"
      :components="components"
      :nodeId="nodeId"
      @new="addComponent"
      @addDep="addDep"
    />
  </div>
</template>
<script>
import * as siteService from "../service/site";
import * as compService from "../service/component";

// import PageHeader from "./components/PageHeader.vue";
import SiteEditPageHeader from "../components/SiteEditPageHeader.vue";
import ComponentTree from '../components/ComponentTree.vue'
import ComponentList from "../components/ComponentList.vue";
import TemplateEditor from "../components/TemplateEditor.vue";
import PropsEdit from "../components/PropsEdit.vue";
import DataSource from "../components/DataSource.vue";
// import Preview from "../components/Preview.vue";
import ComponentConfig from "../components/ComponentConfig.vue";
import AddComponent from '../components/AddComponent.vue'
import CreateSiteModel from "./components/CreateSiteModal.vue";

export default {
  name: "SiteEditor",
  props: {
    isComponentView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // PageHeader,
    SiteEditPageHeader,
    ComponentList,
    TemplateEditor,
    PropsEdit,
    DataSource,
    // Preview,
    ComponentConfig,
    CreateSiteModel,

    ComponentTree,
    AddComponent
  },
  computed: {
    currentComponent() {
      return this.selectedIndex !== -1
        ? this.components[this.selectedIndex]
        : null;
    },
    previewurl() {
      return "//";
    },
    isEditable() {
      return (
        this.isComponentView ||
        (this.currentComponent &&
          (!this.currentComponent.is_global ||
            !!this.forceEdit[this.currentComponent._id]))
      );
    },
    selectedIndex () {
      return this.components.findIndex(cp => cp._id === this.selectedCId)
    }
  },
  watch: {
    isComponentView () {
      this.load()
    }
  },
  data: () => {
    return {
      loading: true,
      siteId: null,
      uri: "",
      versionData: {
        versions: [],
        published_version: '1.0',
        current_version: '1.0',
      },
      site: {
        name: "",
        siteTitle: "",
        extFiles: [],
      },
      selectedCId: -1,
      components: [],
      globalResets: [],
      compPage: {
        page: 0,
        perPage: 20,
        count: 0,
      },
      forceEdit: {},
      nodeId: null,
      selectedVersion: "",
      isOpenSidebar: true,
    };
  },
  mounted() {
    this.load();
    // Drag Js
    var mainContainer = document.querySelector('.drag-container');
    var left = document.getElementById('drag-left');
    var right = document.getElementById('drag-right');
    var bar = document.getElementById('dragbar');

    const minWidth = 100; // Minimum width for left and right panels

    let initialLeftWidth, initialMouseX;

    const drag = (e) => {
      document.selection ? document.selection.empty() : window.getSelection().removeAllRanges();
      let deltaX = e.pageX - initialMouseX;

      let newLeftWidth = initialLeftWidth + deltaX;
      newLeftWidth = Math.max(newLeftWidth, minWidth);

      let maxLeftWidth = mainContainer.offsetWidth - bar.offsetWidth - minWidth;
      newLeftWidth = Math.min(newLeftWidth, maxLeftWidth);

      left.style.width = newLeftWidth + 'px';
      right.style.width = `calc(100% - ${newLeftWidth + bar.offsetWidth}px)`;
        // console.log("width====>", mainContainer.offsetWidth, newLeftWidth, newRightWidth, maxRightWidth)
    };

    bar.addEventListener('mousedown', (e) => {
        initialLeftWidth = left.offsetWidth;
        // initialRightWidth = right.offsetWidth;
        initialMouseX = e.pageX;

        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', stopDrag);
        document.addEventListener('mouseleave', stopDrag);
    });

    const stopDrag = () => {
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', stopDrag);
        document.removeEventListener('mouseleave', stopDrag);
    };
  },
  methods: {
    async load() {
      this.loading = true;
      this.site = {
        name: "loading...",
      };
      if (this.isComponentView) {
        this.site = {
          name: "Manage global components",
        };
        await this.loadGCompos();
      } else {
        await this.loadSitesData();
      }
      this.loading = false;
    },
    async loadGCompos() {
      const res = await compService.getGlobalComponents(
        this.compPage.page,
        this.compPage.perPage
      );
      this.components = res.components;
      this.compPage.count = res.count;
    },
    openAdd (nodeId) {
      this.nodeId = nodeId
      this.$refs.addCompRef.showModal = true
    },
    addDep(compId) {
      const pIdx = this.components.findIndex(c => c._id === this.nodeId)
      this.components[pIdx].deps.push(compId)
    },
    async handleRemoveDep({ nodeId, parentId }) {
      const pIdx = this.components.findIndex(c => c._id === parentId)
      const dIdx = this.components[pIdx].deps.findIndex(c => c === nodeId)
      this.components[pIdx].deps.splice(dIdx, 1)
    },
    async handleRemoveComponent(id) {
      const cidx = this.components.findIndex(c => c._id === id)
      this.components.splice(cidx, 1)
      await compService.updateComponent(id, { is_deleted: true })
      const len = this.components.length
      for(let i = 0; i < len; i ++) {
        const comp = this.components[i]
        const didx = comp.deps.findIndex(did => did === id)
        comp.deps.splice(didx, 1)
      }
      this.saveSiteData()
    },
    async makeGCompCopy() {
      await this.addComponent({
        ...this.currentComponent,
        is_global: false,
        _id: null,
      });
      this.components.splice(this.selectedIndex, 1);
      this.selectedIndex = this.components.length - 1;
      this.saveSiteData();
    },
    async loadSitesData(version) {
      const { uri } = this.$route.params;
      const versionData = await siteService.getSiteVersions(uri);
      this.uri = uri;
      this.selectedVersion = version || versionData.current_version
      const res = await siteService.getSiteWithVersion(uri, version || versionData.current_version);
      this.versionData = versionData
      if (res && res._id) {
        this.siteId = res._id;
        this.components = res.components;
        this.site = {
          ...res,
          ...res.settings,
          extFiles: res.ext_files,
        };
        if (!this.site.name) {
          this.site.name = uri;
        }
      }
    },
    async handleSave() {
      try {
        if (this.isComponentView) {
          const comp = this.currentComponent;
          await compService.updateComponent(comp._id, { component: comp });
        } else {
          await this.saveSiteData();
        }
        this.forceEdit = {};
        this.$notify("Saved.");
      } catch (err) {
        this.$notify({
          type: "warn",
          text: err,
        });
      }
    },
    async createNewSite() {
      this.site.name = this.uri
      await this.addComponent({
        template: `<div>app component template</div>`,
        name: "app",
        props: [],
        data: [],
        deps: [],
      });
      this.saveSiteData();
    },
    async saveSiteData() {
      await Promise.all(
        this.components.map(async (comp) => {
          return await compService.updateComponent(comp._id, {
            component: comp,
          });
        })
      );
      const data = {
        site: {
          uri: this.uri,
          components: this.components.map((comp) => comp._id),
          settings: {
            ...this.site,
            extFiles: undefined
          },
          ext_files: this.site.extFiles,
        },
      };
      if (this.siteId) {
        await siteService.updateSite(this.siteId, data);
      } else {
        const res = await siteService.createSite(data);
        this.siteId = res._id;
      }
      if (this.globalResets.length > 0) {
        await compService.offGlobal(this.globalResets);
      }
      this.globalResets = [];
      await this.loadSitesData(this.selectedVersion);
      // this.$refs.previewRef.reloadIframe()
    },
    selectComponent(id) {
      this.selectedCId = id
    },
    async addComponent(comp) {
      if (comp._id) {
        this.components.push(comp);
      } else {
        const newComp = await compService.createComponent({
          component: {
            ...comp,
            is_global: comp.is_global || this.isComponentView,
          },
        });
        this.components.push(newComp);
        if (this.nodeId) {
          const idx = this.components.findIndex(c => c._id === this.nodeId)
          this.components[idx].deps = (this.components[idx].deps || []).concat(newComp._id)
          this.nodeId = null
        }
      }
    },
    updateTemplate(code) {
      this.components[this.selectedIndex].template = code;
    },
    setProps(list) {
      this.components[this.selectedIndex].props = list;
    },
    setDeps(list) {
      this.components[this.selectedIndex].deps = list;
    },
    setSettings(settings) {
      if (
        !this.components[this.selectedIndex].is_global &&
        settings.is_global
      ) {
        this.forceEdit[this.currentComponent._id] = true;
      }
      if (
        this.components[this.selectedIndex].is_global &&
        !settings.is_global &&
        !this.globalResets.includes(this.components[this.selectedIndex]._id)
      ) {
        this.globalResets.push(this.components[this.selectedIndex]._id);
      }
      Object.keys(settings).forEach((field) => {
        this.components[this.selectedIndex][field] = settings[field];
      });
    },
    handleSourceUpdate({ idx, data }) {
      this.components[this.selectedIndex].data[idx] = data;
    },
    handleRemoveDataSource(idx) {
      this.components[this.selectedIndex].data.splice(idx, 1);
    },
    handleAddDataSource({name, type}) {
      let remoteItem = {}
      if (type === 'remote') {
        remoteItem = {
          method: 'GET',
          resolve: {
            section: 'res',
            url_params: [],
            headers: []
          }
        }
      }
      this.components[this.selectedIndex].data.push({
        key: name,
        type,
        ...remoteItem,
        default_value: null,
      });
    },
    updateSiteSettings(settings) {
      this.site = {
        ...this.site,
        ...settings,
      };
    },
    async createGlobalComponent () {
      const { uri } = this.$route.params;
      await compService.createComponent({
        component: {
          template: `<div>${uri} component template</div>`,
          name: uri,
          props: [],
          data: [],
          deps: [],
          is_global: true
        },
      })
      this.$router.push({
        name: 'ComponentsEditor',
        props: { isComponentView: true }
      })
    },
    async updateURI () {
      const newUri = window.prompt('New Site URI for ' + this.$route.params.uri)
      if (!newUri) { return }
      try {
        const res = await siteService.updateSite(this.siteId, { site: { uri: newUri } })
        if (res.error) {
          this.$notify({
            type: "warn",
            text: `${res.error}`,
          });
          return
        }
        this.$router.push({
          name: 'SiteEditor',
          params: { uri: newUri }
        })
        setTimeout(() => {
          window.location.reload()
        }, 100)
      } catch (err) {
        this.$notify({
          type: "warn",
          text: `${err}`,
        });
      }
    },
    async deleteSite () {
      try {
        if (window.confirm('Are you sure?')) {
          const res = await siteService.deleteSite(this.siteId)
          if (res.error) {
            this.$notify({
              type: "warn",
              text: `${res.error}`,
            });
            return
          }
          this.$router.push({
            name: 'SitesList'
          })
        }
      } catch (err) {
        this.$notify({
          type: "warn",
          text: `${err}`,
        });
      }
    },
    selectVersion (version) {
      this.loadSitesData(version)
    },
    async addNewVersion () {
      const newVersion = window.prompt(`Input new version number. larger than ${this.versionData.current_version}`)
      await siteService.updateSiteVersion(this.uri, this.selectedVersion, newVersion)
      this.loadSitesData(newVersion)
      // await siteService.setSiteVersion(this.uri, {
      //   published_version: this.versionData.published_version,
      //   current_version: newVersion
      // })
    },
    async setMetaVersion (metaVersion) {
      await siteService.setSiteVersion(this.uri, {
        ...this.versionData,
        ...metaVersion
      })
      this.loadSitesData(this.selectedVersion)
    },
    async deleteVersion (version) {
      await siteService.deleteSiteVersion(this.uri, version)
      this.loadSitesData()
    },
    toggleSidebar(){
      this.isOpenSidebar = !this.isOpenSidebar
    }
  },
};
</script>
<style lang="scss" scoped>
.settings-wrapper {
  width: 30%;
}
#drag-left{
  width: 70%;
}
.main-container{
  background: #f8f8ff;
}
.close-sidebar{
  margin-left: -260px !important;
}
.main-container{
  height: calc(100vh - 60px);
}
.main-editor-wrapper{
  overflow: hidden;
  height: 100vh !important;
}
</style>
