<template>
  <div class="flex flex-col">
    <div class="font-bold mb-2">
      Components
    </div>
    <div
      v-for="comp of orderedComponents"
      :key="comp._id"
      class="cursor-pointer nav-link"
      :class="{
        'active': currentComponent && currentComponent._id === comp._id
      }"
      @click="select(comp._id)"
    >
      <div class="flex justify-between pr-2 items-center">
        {{ comp.name }}
        <i title="Used" class="fa-solid fa-check ml-auto mr-2"></i>
        <button v-if="!isComponentView && comp.name !== 'app'" @click="handleComponentDelete(comp._id)">
          <i class="fa-solid fa-trash-can"></i>
        </button>
      </div>
    </div>
    <button
      class="w-full flex items-center mt-2"
      @click="$emit('openAdd', null)"
    >
      <span class="mr-2">Add component</span>
      <font-awesome-icon icon="plus"/>
    </button>
  </div>
</template>
<script>

export default {
  name: 'ComponentList',
  props: {
    components: {
      type: Array,
      default: () => ([])
    },
    currentComponent: {
      type: Object,
      default: () => -1
    },
    isComponentView: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    orderedComponents () {
      const allDeps = this.components.reduce((deps, comp) => deps.concat(comp.deps), [])
      console.log('allDeps: ', allDeps);
      return this.components.map(comp => {
        return {
          ...comp,
          isUsed: allDeps.includes(comp._id)
        }
      }).sort((cp1, cp2) => {
        return cp2.isUsed - cp1.isUsed
      }).filter(cp => cp.name !== 'app')
    }
  },
  data () {
    return {
      newName: ''
    }
  },
  methods: {
    select (idx) {
      this.$emit('select:component', idx)
    },
    handleComponentDelete (id) {
      if (window.confirm("Are you sure want to delete this component?")) {
        this.$emit('remove', id)
      }
    }
  }
}
</script>
