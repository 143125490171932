<template>
  <div class="side-bar-card mb-1">
    <h3>Component settings</h3>
    <div v-if="currentComponent.name !== 'app'" class="form-check my-2">
      <input class="form-check-input" v-model="is_global" :disabled="isComponentView" type="checkbox" value="" id="flexCheckChecked">
      <label class="form-check-label" for="flexCheckChecked">
        This is global component
      </label>
    </div>
    <h3 :class="{'mt-2': currentComponent.name == 'app'}">Name</h3>
    <div class="side-bar-input">
      <input type="text" name="" id="" v-model="name" :disabled="is_global"  placeholder="app-header">
    </div>
  </div>
</template>
<script>
export default {
  name: 'ComponentConfig',
  props: {
    currentComponent: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      required: true
    },
    isComponentView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      is_global: false,
      name: ''
    }
  },
  watch: {
    settings: {
      handler (v) {
        Object.keys(v).forEach(field => {
          this[field] = v[field]
        })
      },
      immediate: true
    },
    is_global (v) {
      this.$emit('settings:change', { is_global: v })
    },
    name (v) {
      this.$emit('settings:change', { name: v })
    }
  }
}
</script>
